<template>
  <div v-if="Object.keys(profileData).length" id="user-profile">
    <section id="profile-info">
      <b-row>

        <!-- about suggested page and twitter feed -->
        <b-col lg="12" cols="12" order="1" order-lg="1">
          <profile-about :about-data="profileData.userAbout" />
          <!-- <profile-suggested-pages :pages-data="profileData.suggestedPages" />
          <profile-twitter-feed :twitter-feed="profileData.twitterFeeds" /> -->
        </b-col>

        <!--/ load more  -->
      </b-row>
    </section>
    <!--/ profile info  -->
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import { kFormatter } from "@core/utils/filter";
import ProfileAbout from "./ProfileAbout.vue";

/* eslint-disable global-require */
export default {
  components: {
    StatisticCardWithAreaChart,
    BRow,
    BCol,
    ProfileAbout,

  },
  data() {
    return {
      profileData: {},
      data: {},
      dataCards: {},
    };
  },
  created() {
    this.$http.get("/analytics/data").then((response) => {
      this.dataCards = response.data;
    });

    this.$http.get("/ecommerce/data").then((response) => {
      this.data = response.data;

      // ? Your API will return name of logged in user or you might just directly get name of logged in user
      // ? This is just for demo purpose
      const userData = getUserData();
      this.data.congratulations.name =
        userData.fullName.split(" ")[0] || userData.username;
    });
    this.$http.get("/profile/data").then((res) => {
      this.profileData = res.data;
    });
  },
  methods: {
    kFormatter,
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss" >
@import "@core/scss/vue/pages/page-profile.scss";
</style>
