<template>
  <b-card>
    <!-- about -->
    <!-- <div
      v-for="(data,key,index) in userData"
      :key="data.title"
      :class="index ? 'mt-2':''"
    >
      <h5 class="text-capitalize mb-75">
        {{ key }}
      </h5>
      <b-card-text>
        {{ data }}
      </b-card-text>
    </div> -->
     <div
    >
      <h5 class="text-capitalize mb-75">
        Name
      </h5>
      <b-card-text>
        {{ userData.name }}
      </b-card-text>
          <h5 class="text-capitalize mb-75">
        Email
      </h5>
      <b-card-text>
        {{ userData.email }}
      </b-card-text>

          <h5 class="text-capitalize mb-75" v-if="userData.mobile">
        Mobile
      </h5>
      <b-card-text v-if="userData.mobile"4>
        {{ userData.mobile }}
      </b-card-text>

          <h5 class="text-capitalize mb-75" v-if="userData.adresse">
        Adresse
      </h5>
          <b-card-text v-if="userData.adresse">
        {{ userData.adresse.city }} , {{ userData.adresse.country }}
      </b-card-text>

 <b-button v-if="$route.query.id"
                  variant="primary"
                  class="btn-icon"
                  @click="chatStart()"
                >
                  <!-- @click="DeleteCategory(props.row._id)" -->

                  Chat
                </b-button>
    </div> 
  </b-card>
</template>

<script>
import { BCard, BCardText, BButton } from 'bootstrap-vue'
import axios from "axios";

export default {
  components: {
    BCard,
    BCardText,
    BButton
  },
  props: {
    aboutData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
          userData:{}
    }
  },
  created(){
    if(this.$route.query.id){
     axios
        .get(
          "https://backendapinodejs.timecheckit.com/api/users?id=" + this.$route.query.id
        )
        .then((response) => {
          console.log("test",response.data.data);
          this.userData =response.data.data
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }else{
       axios
        .get(
          "https://backendapinodejs.timecheckit.com/api/users?id=" + JSON.parse(localStorage.getItem("userData"))._id
        )
        .then((response) => {
          console.log("test",response.data.data);
                    this.userData =response.data.data

        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
      
  },
  methods:{
    chatStart(){
       axios
          .post("https://backendapinodejs.timecheckit.com/api/chatRoom", { 
            userId: this.$route.query.id,
            users: [JSON.parse(localStorage.getItem("userData"))._id,this.$route.query.id]
           })
          .then((response) => {
            console.log(response);
          this.$router.push('/chat')
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
  }
}
</script>
